import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link, useNavigate, useParams} from 'react-router-dom'
import axiosInstance from '../../../api/axios'
import Swal from 'sweetalert2'
import {motion} from 'framer-motion'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import 'flatpickr/dist/themes/light.css'
import Flatpickr from 'react-flatpickr'
import {FormControl, InputLabel, MenuItem, Select, Skeleton} from '@mui/material'
import moment from 'moment'
import ReactTable from '../../../../_metronic/partials/widgets/React_Table/ReactTable'
import useAuthContext from '../../../Auth/AuthContext'
import {MdCloudUpload} from 'react-icons/md'

const UpdateGroups = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [Items, setItems] = useState([])
  const navigate = useNavigate()
  const [imported, setImported] = useState([])
  const [fileName, setFileName] = useState('No selected file')

  const {id} = useParams()

  const {check_role} = useAuthContext()

  const [data, setData] = useState({
    name_ar: '',
    name_en: '',
    img: null,
    percentage: 0,
    fixed: 0,
    type: 'fixed',
  })

  const [step, setStep] = useState(1)

  const types = [
    {id: 'fixed', name: 'fixed'},
    {id: 'percentage', name: 'percentage '},
  ]

  const handleChange = (e) => {
    const {name, value} = e.target
    setData((prevData) => ({...prevData, [name]: value}))
  }

  const handleEdit_Add_submit = async (event) => {
    setLoading(true)
    event.preventDefault()
    const formData = new FormData()
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value)
    })
    formData.append('data', JSON.stringify(imported))
    formData.append('_method', 'Patch')
    try {
      await axiosInstance.post(`/admin-groups/${id}`, formData).then((response) => {
        Swal.fire({
          title: 'Success',
          icon: 'success',
          confirmButtonText: 'OK',
        })
        setLoading(false)
        setErrors([])
        navigate(`/Groups`)
      })
    } catch (e) {
      console.log(e.response.data.errors)
      if (e.response.status === 422) {
        setLoading(false)
        setErrors(e.response.data.errors)
      }
    }
  }

  // get data
  const get_data = async () => {
    setItems([])
    try {
      const [groupResponse, itemsGroupResponse] = await Promise.all([
        axiosInstance.get(`/admin-groups/${id}`),
        axiosInstance.get('/admin-cats'),
      ])

      const groupData = groupResponse.data

      setData({
        name_ar: groupData.name_ar,
        name_en: groupData.name_en,
        img: groupData.img ?? null,
        percentage: groupData.percentage ?? 0,
        fixed: groupData.fixed ?? 0,
        type: groupData.type,
      })

      let selectedCats = []
      if (groupData.cats) {
        selectedCats = groupData.cats.map((cat) => ({
          id: cat.id,
          name_en: cat.name_en,
          name_ar: cat.name_ar,
        }))
        setImported(selectedCats)
      }

      const allItems = itemsGroupResponse.data

      // ترتيب القائمة بحيث تكون العناصر المحددة في الأعلى
      const sortedItems = [
        ...selectedCats,
        ...allItems.filter((item) => !selectedCats.some((cat) => cat.id === item.id)),
      ]

      setItems(sortedItems)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const add_item = (item) => {
    const rowsInput = {
      id: item.id,
      name_en: item.name_en,
      name_ar: item.name_ar,
    }
    setImported((prevImported) => [...prevImported, rowsInput])
  }

  const remove_item = (item) => {
    setImported((prevImported) =>
      prevImported.filter((importedItem) => importedItem.id !== item.id)
    )
  }

  const handle_change = (e, item) => {
    if (e.target.checked) {
      add_item(item)
    } else {
      remove_item(item)
    }
  }

  const isChecked = (id) => {
    return imported.some((importedItem) => importedItem.id === id)
  }
  const changeHandler_iamge = (event) => {
    const file = event.target.files[0]
    console.log('Selected File:', file) // Add this line for debugging
    if (file) {
      setData((prevData) => ({...prevData, img: file}))
      setFileName(file.name)
    } else {
      console.warn('No file selected or invalid file')
    }
  }

  const columns = [
    {
      Header: intl.formatMessage({id: 'Table.ID'}),
      accessor: 'productID',
      Cell: (row) => (
        <div className='w-100 flex-center'>
          <div className='form-check form-check-sm form-check-custom form-check-solid flex-center'>
            <input
              className='form-check-input widget-9-check me-2'
              onChange={(e) => {
                handle_change(e, row.row.original)
              }}
              type='checkbox'
              checked={isChecked(row.row.original.id)}
            />
            # {row.row.original.id}
          </div>
        </div>
      ),
    },

    {
      Header: intl.formatMessage({id: 'Table.Supplier'}),
      accessor: 'type',
      Cell: ({row}) => <div className='w-100 flex-center'>{row.original.type ?? '---'}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.DescriptionEN'}),
      accessor: 'name_en',
      Cell: ({row}) => <div className='w-100 flex-center'>{row.original.name_en}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.DescriptionAR'}),
      accessor: 'name_ar',
      Cell: ({row}) => <div className='w-100 flex-center'>{row.original.name_ar}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.File'}),
      accessor: 'img',
      Cell: ({row}) => (
        <div className='symbol symbol-50px'>
          <img
            src={
              row.original.img
                ? process.env.REACT_APP_IMAGE_PATH + row.original.img
                : toAbsoluteUrl('media/avatars/300-6.jpg')
            }
            alt=''
          />
        </div>
      ),
    },
    {
      Header: intl.formatMessage({id: 'Table.default_image'}),
      accessor: 'item_img',
      Cell: ({row}) => (
        <div className='symbol symbol-50px'>
          <img
            src={
              row.original.item_img
                ? process.env.REACT_APP_IMAGE_PATH + row.original.item_img
                : toAbsoluteUrl('media/avatars/300-6.jpg')
            }
            alt=''
          />
        </div>
      ),
    },
  ]

  useEffect(() => {
    if (!check_role('show-groups')) {
      navigate('/')
    }
    get_data()
  }, [])

  // console.log(data)

  return (
    <motion.nav
      initial={{opacity: 0, x: 100}}
      animate={{opacity: 1, x: 0, transition: {duration: 0.3}}}
      exit={{opacity: 0, x: 100, transition: {duration: 0.2}}}
    >
      <div className=' mb-5 mt-5 d-flex flex-stack'>
        <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
          <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
            {intl.formatMessage({id: 'Groups.Edit'})}
          </h1>
          <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
            <li className='breadcrumb-item text-muted'>
              <span className='text-muted text-hover-primary'>
                {' '}
                {intl.formatMessage({id: 'Menu.Groups'})}
              </span>
            </li>
            <li className='breadcrumb-item'>
              <span className='bullet bg-gray-400 w-5px h-2px'></span>
            </li>
            <li className='breadcrumb-item text-muted'>
              {intl.formatMessage({id: 'Menu.Groups'})}
            </li>
          </ul>
        </div>
        <div>
          <Link to='/Groups' type='button' className='btn btn-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-2' />
            {intl.formatMessage({id: 'Form.GoBack'})}
          </Link>
        </div>
      </div>
      <div id='kt_app_content_container' className='app-container container-xxl'>
        <form
          onSubmit={handleEdit_Add_submit}
          id='kt_ecommerce_add_category_form'
          className='form d-flex flex-column flex-lg-row fv-plugins-bootstrap5 fv-plugins-framework'
          data-kt-redirect='../../demo1/dist/apps/ecommerce/catalog/categories.html'
        >
          <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
            {step === 1 && (
              <>
                <div className='card card-flush py-4'>
                  <div className='card-header'>
                    <div className='card-title'>
                      <h2>{intl.formatMessage({id: 'Form.General'})} </h2>
                    </div>
                  </div>
                  <div className="d-flex">
  {/* Image Upload Section */}
  <div className="w-100 w-lg-300px mb-7 me-lg-10">
    <div className="card card-flush py-4">
      <div className="card-header">
        <h2 className="card-title">{intl.formatMessage({ id: "Form.ImageContent" })}</h2>
      </div>
      <div className="card-body text-center pt-0">
        <div className="image-input image-input-empty image-input-outline image-input-placeholder mb-3">
          <div
            className="file"
            onClick={() => document.querySelector('.input-field2').click()}
          >
            <input
              type="file"
              accept="image/*"
              className="input-field2"
              name="img"
              hidden
              onChange={changeHandler_iamge}
            />
            {data.img ? (
              typeof data.img === 'string' ? (
                <img
                  src={`${process.env.REACT_APP_IMAGE_PATH}${data.img}`}
                  width={150}
                  height={150}
                  alt={fileName}
                />
              ) : (
                <img
                  src={URL.createObjectURL(data.img)}
                  width={150}
                  height={150}
                  alt={fileName}
                />
              )
            ) : (
              <>
                <MdCloudUpload color="#1475cf" size={60} />
                <p>{intl.formatMessage({ id: 'Form.BrowseFilestoupload' })}</p>
              </>
            )}
          </div>
          {errors.img && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{errors.img}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  </div>

  {/* Form Fields Section */}
  <div className="card-body pt-0">
    <div className="row flex-between">
      {/* Name (Arabic) */}
      <div className="my-5 col-6 fv-row fv-plugins-icon-container">
        <label className="required form-label">
          {intl.formatMessage({ id: "Form.NameAR" })}
        </label>
        <input
          type="text"
          name="name_ar"
          className="form-control py-5"
          value={data.name_ar}
          onChange={handleChange}
          placeholder={intl.formatMessage({ id: "Form.NameAR" })}
        />
        {errors.name_ar && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{errors.name_ar}</span>
            </div>
          </div>
        )}
      </div>

      {/* Name (English) */}
      <div className="my-5 col-6 fv-row fv-plugins-icon-container">
        <label className="required form-label">
          {intl.formatMessage({ id: "Form.NameEN" })}
        </label>
        <input
          type="text"
          name="name_en"
          className="form-control py-5"
          value={data.name_en}
          onChange={handleChange}
          placeholder={intl.formatMessage({ id: "Form.NameEN" })}
        />
        {errors.name_en && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{errors.name_en}</span>
            </div>
          </div>
        )}
      </div>

      {/* Type Dropdown */}
      <div className="my-5 col-6 fv-row fv-plugins-icon-container">
        <label className="form-label">{intl.formatMessage({ id: "Form.Type" })}</label>
        <select
          name="type"
          className="form-control py-5"
          value={data.type}
          onChange={handleChange}
        >
          {types.map(({ id, name }) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
        </select>
      </div>

      {/* Conditional Fields */}
      {data.type === 'fixed' ? (
        <div className="my-5 col-6 fv-row fv-plugins-icon-container">
          <label className="required form-label">
            {intl.formatMessage({ id: "Form.Fixed" })}
          </label>
          <input
            type="number"
            name="fixed"
            className="form-control py-5"
            value={data.fixed}
            onChange={handleChange}
            placeholder={intl.formatMessage({ id: "Form.Fixed" })}
          />
          {errors.fixed && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{errors.fixed}</span>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="my-5 col-6 fv-row fv-plugins-icon-container">
          <label className="required form-label">
            {intl.formatMessage({ id: "Form.Percentage" })}
          </label>
          <input
            type="number"
            name="percentage"
            className="form-control py-5"
            value={data.percentage}
            onChange={handleChange}
            placeholder={intl.formatMessage({ id: "Form.Percentage" })}
          />
          {errors.percentage && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{errors.percentage}</span>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  </div>
</div>

                </div>
              </>
            )}
            <>
              <ReactTable columns={columns} data={Items} customComponent={<></>} />
              <div className='row flex-between my-3 justify-content-end'>
                {check_role('edit-groups') && (
                  <button type='submit' className='btn col-2 btn-primary'>
                    {!loading && (
                      <span className='indicator-label'>
                        {intl.formatMessage({id: 'Form.Edit'})}{' '}
                      </span>
                    )}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        {intl.formatMessage({id: 'Form.Pleasewait'})}{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                )}
              </div>
            </>
          </div>
        </form>
      </div>
    </motion.nav>
  )
}

export default UpdateGroups
