import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import ReactTable from '../../../../_metronic/partials/widgets/React_Table/ReactTable'
import { Skeleton } from '@mui/material'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

export const AnaltyticsProducts = ({ data }) => {
    const [loading, setLoading] = useState(true)
    const intl = useIntl()


    console.log('product_data',data);



    const TruncateString = ({ text }) => {
        const truncatedText = text ? text.slice(0, 50) : ''
        return truncatedText
    }
    const columns = [
        {
          Header: intl.formatMessage({id: 'Table.ID'}),
          accessor: 'id',
          Cell: ({row}) => <div className='w-100 flex-center'># {row.original.id}</div>,
        },
        {
          Header: intl.formatMessage({id: 'Table.Description'}),
          accessor: 'name_en',
          Cell: (row) => (
            <div className='w-300 flex-center fs-12 f-bold'>
              <div>
                <TruncateString text={row.row.original.name_en} />
              </div>
              <div>
                <TruncateString text={row.row.original.name_ar} />
              </div>
            </div>
          ),
        },
    
        {
          Header: intl.formatMessage({id: 'Table.File'}),
          accessor: 'img',
          Cell: (row) => (
            <div className='symbol symbol-50px'>
              <img
                src={
                  row.row.original.img
                    ? process.env.REACT_APP_IMAGE_PATH + row.row.original.img
                    : toAbsoluteUrl('media/avatars/300-6.jpg')
                }
                alt=''
              />
            </div>
          ),
        },
        {
          Header: intl.formatMessage({id: 'Table.Price'}),
          accessor: 'price',
          Cell: ({row}) => <div className='w-100 flex-center'>{row.original.price}</div>,
        },
        {
          Header: intl.formatMessage({id: 'Table.Count'}),
          accessor: 'count',
          Cell: ({row}) => <div className='w-100 flex-center'>{row.original.count}</div>,
        },
        {
          Header: intl.formatMessage({id: 'Table.Total'}),
          accessor: 'total',
          Cell: ({row}) => <div className='w-100 flex-center'>{row.original.total.toFixed(2) }</div>,
        },
      ]
    useEffect(() => {
        if (data) {
            setLoading(false)
        }
    }, [data])
    return data !== null && !loading ?
        <ReactTable pageSize={5} columns={columns} data={data} customComponent={null} />
        :
        <div className="row">
            <div className="col-12">
                <Skeleton variant="rounded" animation="wave" sx={{ width: '100%', height: '90vh', marginTop: '1vh' }} />
            </div>
        </div>
}
