import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import axiosInstance from '../../../api/axios'
import Swal from 'sweetalert2'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Link, useNavigate} from 'react-router-dom'
import {motion} from 'framer-motion'
import ProductsAutoComplete from '../../../../_metronic/partials/widgets/AutoComplete/ProductsAutoComplete'

import useAuthContext from '../../../Auth/AuthContext'
import StockModal from './StockModal'

const AddProductStok = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [user_value, setUSER_Value] = useState(null)
  const [Item, setItem] = useState(null)
  const [Import, setImport] = useState(null)
  const [showModal, setShowModal] = useState(false)

  const {check_role} = useAuthContext()
  const navigate = useNavigate()

  const get_data = async () => {
    setLoading(true)
    const {data} = await axiosInstance.get('/admin-stocks', {
      params: {
        id: user_value.id,
      },
    })
    setItem(data)
    setLoading(false)
  }

  const [rowsData, setRowsData] = useState([
    {
      code: '',
      pin: '',
    },
  ])

  const addTableRows = () => {
    const rowsInput = {
      code: '',
      pin: '',
    }
    setRowsData([...rowsData, rowsInput])
  }

  const deleteTableRows = (index) => {
    const rows = [...rowsData]
    rows.splice(index, 1)
    setRowsData(rows)
  }

  const handleChange2 = (index, evnt) => {
    const {name, value} = evnt.target
    const rowsInput = [...rowsData]
    rowsInput[index][name] = value
    setRowsData(rowsInput)
    console.log(rowsData)
  }

  const handleEdit_Add_submit = async (event) => {
    setLoading(true)
    event.preventDefault()
    const formData = new FormData()
    formData.append('details', JSON.stringify(rowsData))
    formData.append('item_id', Item.id)
    try {
      await axiosInstance.post(`/admin-stocks`, formData).then((response) => {
        Swal.fire({
          title: 'Success',
          icon: 'success',
          confirmButtonText: 'OK',
        })
        setLoading(false)
        setRowsData([
          {
            code: '',
            pin: '',
          },
        ])
      })
    } catch (e) {
      console.log(e.response.data.errors)
      if (e.response.status === 422) {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    if (!check_role('add-Stocks')) {
      navigate('/')
    }
  }, [])
  return (
    <motion.nav
      initial={{opacity: 0, x: 100}}
      animate={{opacity: 1, x: 0, transition: {duration: 0.3}}}
      exit={{opacity: 0, x: 100, transition: {duration: 0.2}}}
    >
      <div className=' mb-5 mt-5 d-flex flex-stack'>
        <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
          <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
            {intl.formatMessage({id: 'Stock.Create'})}
          </h1>
          <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
            <li className='breadcrumb-item text-muted'>
              <li className='breadcrumb-item text-muted'>
                {intl.formatMessage({id: 'Menu.Home'})}
              </li>
            </li>
            <li className='breadcrumb-item'>
              <span className='bullet bg-gray-400 w-5px h-2px'></span>
            </li>
            <li className='breadcrumb-item text-muted'>
              {intl.formatMessage({id: 'Menu.Stocks'})}
            </li>
          </ul>
        </div>
      </div>
      <div className='card my-3 p-5'>
        <div className='row flex-between align-items-end'>
          <div className='col-6'>
            <div className=' fv-row fv-plugins-icon-container'>
              <ProductsAutoComplete value={user_value} setValue={setUSER_Value} />
              <div className='fv-plugins-message-container invalid-feedback' />
            </div>
          </div>
          <div className='col-3'>
            <button onClick={get_data} type='button' className='btn w-100 btn-light-primary py-5'>
              {!loading && (
                <div className=''>
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'Table.Search'})}
                    <KTSVG
                      path='/media/icons/duotune/general/gen004.svg'
                      className='svg-icon-2 ms-3'
                    />
                  </span>
                </div>
              )}

              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'Form.Pleasewait'})}{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>

          <div className='col-3'>
            <button
              onClick={() => setShowModal(true)}
              type='button'
              className='btn py-3 py-md-5 btn-light-primary w-100'
            >
              <KTSVG path='/media/icons/duotune/files/fil022.svg' className='svg-icon-2' />
              {intl.formatMessage({id: 'Table.Import'})}
            </button>
          </div>
        </div>
      </div>
      {Item && !loading && (
        <form
          onSubmit={handleEdit_Add_submit}
          id='kt_ecommerce_add_category_form'
          data-kt-redirect='../../demo1/dist/apps/ecommerce/catalog/categories.html'
        >
          <div className='card card-flush py-4'>
            <div className='card-header'>
              <div className='card-title'>
                <h2>{intl.formatMessage({id: 'Table.Codes'})}</h2>
              </div>
            </div>
            <div className='card-body pt-0'>
              <div className='row'>
                <div className='mb-3 fv-row fv-plugins-icon-container mt-3 '>
                  <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                    <thead className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                      <tr>
                        <th>{intl.formatMessage({id: 'Form.Code'})}</th>
                        <th>{intl.formatMessage({id: 'Form.Refnumber'})}</th>
                        <th>
                          <button
                            type='button'
                            className='btn me-2 btn-light-primary'
                            onClick={addTableRows}
                          >
                            +
                          </button>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {rowsData.map((row, key) => (
                        <tr key={key}>
                          <td style={{width: '400px'}}>
                            <input
                              type='text'
                              value={row.pin}
                              onChange={(evnt) => handleChange2(key, evnt)}
                              name='pin'
                              className='form-control'
                            />
                          </td>
                          <td style={{width: '400px'}}>
                            <input
                              type='text'
                              value={row.code}
                              onChange={(evnt) => handleChange2(key, evnt)}
                              name='code'
                              className='form-control'
                            />
                          </td>
                          <td>
                            <div className='d-flex flex-center'>
                              <button
                                type='button'
                                className='btn me-2 btn-light-primary'
                                onClick={addTableRows}
                              >
                                +
                              </button>
                              <button
                                className='btn me-2 btn-light-danger'
                                type='button'
                                onClick={() => deleteTableRows(key)}
                              >
                                x
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className='d-flex justify-content-end  mt-3'>
            <button type='submit' className='btn btn-primary'>
              {!loading && (
                <span className='indicator-label'>{intl.formatMessage({id: 'Form.Create'})} </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'Form.Pleasewait'})}{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      )}
      <StockModal show={showModal} get_data={get_data} handleClose={() => setShowModal(false)} />{' '}
    </motion.nav>
  )
}
//get-banners
export default AddProductStok
