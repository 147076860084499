import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import axiosInstance from '../../../api/axios'
import Swal from 'sweetalert2'
import {KTSVG} from '../../../../_metronic/helpers'
import {Link, useNavigate} from 'react-router-dom'
import {motion} from 'framer-motion'
import ReactTable from '../../../../_metronic/partials/widgets/React_Table/ReactTable'
import {Skeleton, Pagination} from '@mui/material'
import useAuthContext from '../../../Auth/AuthContext'
import { Images } from '../../../../_metronic/partials/widgets/React_Table/Images'

const GroupsHome = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [Items, setItems] = useState([])
  const [searchQuery, setSearchQuery] = useState('')

  const {check_role} = useAuthContext()
  const navigate = useNavigate()

  const [check, setCheck] = useState({
    browse: false,
    add: false,
    show: false,
    edit: false,
    delete: false,
  })

  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 10

  const CustomDiv = () => (
    <div className='row flex-end w-100'>
      {check.add && (
        <div className='col-auto'>
          <Link to='/Groups/Add-Groups' type='button' className='btn btn-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            {intl.formatMessage({id: 'Groups.Create'})}
          </Link>
        </div>
      )}
    </div>
  )

  const Delete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      preConfirm: async (login) => {
        try {
          const {data} = await axiosInstance.delete(`/admin-groups/${id}`)
          get_data()
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`)
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Done!',
          icon: 'success',
        })
      }
    })
  }

  const edit_status = async (value, id) => {
    let endpoint = `/change-groups-status/${id}/${value}`
    try {
      await axiosInstance.get(endpoint)
      get_data()
    } catch (error) {
      console.error(error)
    }
  }

  const columns = [
    {
      Header: intl.formatMessage({id: 'Table.ID'}),
      accessor: 'id',
      Cell: (row) => <div className='w-100 flex-center'># {row.row.original.id}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.Name'}),
      accessor: 'Name_en',
      Cell: (row) => <div className='w-100 flex-center'>{row.row.original.name_en} </div>,
    },
    {
      Header: intl.formatMessage({ id: 'Table.Img' }),
      accessor: (row) => `${row.name} `,
      Cell: (row) => (
        <div className='d-flex justify-content-center align-items-center ps-3'>
          <Images img={row.row.original.img} name={row.row.original.name} />
        </div>
      ),
    },    

    {
      Header: intl.formatMessage({id: 'Table.Type'}),
      accessor: 'type',
      Cell: (row) => <div className='w-100 flex-center'>{row.row.original.type}</div>,
    },

    {
      Header: intl.formatMessage({id: 'Table.Value'}),
      accessor: 'value',
      Cell: (row) => (
        <div className='w-100 flex-center'>
          {row.row.original.type === 'fixed'
            ? `${row.row.original.fixed} SAR`
            : `${row.row.original.percentage}%`}
        </div>
      ),
    },

    {
      Header: 'Default',
      accessor: 'default',
      Cell: ({row}) => (
        <div className='form-check mt-2 form-switch form-switch-sm form-check-custom form-check-solid flex-center'>
          {check.edit && (
            <input
              className='form-check-input'
              type='checkbox'
              defaultChecked={row.original.default}
              onClick={() => edit_status(!row.original.default, row.original.id)}
            />
          )}
        </div>
      ),
    },
    {
      Header: intl.formatMessage({id: 'Table.UsersCount'}),
      accessor: 'users_count',
      Cell: (row) => <div className='w-100 flex-center'>{row.row.original.users_count}</div>,
    },

    {
      Header: intl.formatMessage({id: 'Table.Action'}),
      Cell: (row) => (
        <div className='d-flex flex-center'>
          {check.edit && (
            <>
              <Link
                to={`/Groups/Edit-Groups/${row.row.original.id}`}
                className='btn me-2 btn-light-primary'
              >
                {intl.formatMessage({id: 'Table.Edit'})}
              </Link>
              <Link
                to={`/Groups/Users-Groups/${row.row.original.id}`}
                className='btn me-2 btn-light-success'
              >
                {intl.formatMessage({id: 'Menu.Users'})}
              </Link>
            </>
          )}
          {check.delete && (
            <button
              onClick={() => Delete(row.row.original.id)}
              className='btn me-2 btn-light-danger'
            >
              {intl.formatMessage({id: 'Table.Delete'})}
            </button>
          )}
        </div>
      ),
    },
  ]

  const get_data = async () => {
    setLoading(true)
    try {
      const {data} = await axiosInstance.get('/admin-groups')
      setItems(data || [])
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setCheck({
      browse: check_role('browse-groups'),
      add: check_role('add-groups'),
      show: check_role('show-groups'),
      edit: check_role('edit-groups'),
      delete: check_role('delete-groups'),
    })

    if (!check_role('browse-groups')) {
      navigate('/')
    }
    get_data()
  }, [])

  // Filter items based on search query
  const filteredItems =
    Items.filter(
      (item) =>
        (item.name?.toLowerCase()?.includes(searchQuery.toLowerCase()) || '') &&
        (item.type?.toLowerCase()?.includes(searchQuery.toLowerCase()) || '')
    ) || []

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem)

  const handlePageChange = (event, value) => {
    setCurrentPage(value)
  }

  return (
    <motion.nav
      initial={{opacity: 0, x: 100}}
      animate={{opacity: 1, x: 0, transition: {duration: 0.3}}}
      exit={{opacity: 0, x: 100, transition: {duration: 0.2}}}
    >
      <div className='mb-5 mt-5 d-flex flex-stack'>
        <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
          <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
            {intl.formatMessage({id: 'Menu.Groups'})}
          </h1>
          <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
            <li className='breadcrumb-item text-muted'>{intl.formatMessage({id: 'Menu.Home'})}</li>
            <li className='breadcrumb-item'>
              <span className='bullet bg-gray-400 w-5px h-2px'></span>
            </li>
            <li className='breadcrumb-item text-muted'>
              {intl.formatMessage({id: 'Menu.Groups'})}
            </li>
          </ul>
        </div>
      </div>

      {/* Table view on large screens */}
      <div className='d-none d-lg-block'>
        {!loading ? (
          <ReactTable columns={columns} data={Items} customComponent={<CustomDiv />} />
        ) : (
          <Skeleton
            variant='rounded'
            animation='wave'
            sx={{width: '100%', height: '90vh', marginTop: '1vh'}}
          />
        )}
      </div>

      {/* Card view on small screens with pagination */}
      <div className='d-lg-none'>
        <div className='mb-3'>
          <CustomDiv />
        </div>
        <div className='mb-3'>
          <input
            type='text'
            className='form-control'
            placeholder={intl.formatMessage({id: 'Search.Input'})}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

        {currentItems.length > 0 ? (
          currentItems.map((item) => (
            <div
              className='card mb-3 shadow-sm border-0 rounded'
              key={item.id}
              style={{backgroundColor: '#f8f9fa'}}
            >
              <div className='card-body p-4'>
                <h5 className='card-title text-primary fw-bold'>
                  {intl.formatMessage({id: 'Table.ID'})}: #{item.id}
                </h5>
                <p className='card-text mb-2'>
                  <strong>{intl.formatMessage({id: 'Table.Name'})}:</strong> {item.name}
                </p>
                <p className='card-text mb-2'>
                  <strong>{intl.formatMessage({id: 'Table.Type'})}:</strong>{' '}
                  {item.type === 'fixed' ? 'percentage %' : 'direct discount $'}
                </p>
                <p className='card-text mb-2 d-flex align-items-center'>
                  <strong>{intl.formatMessage({id: 'Table.Status'})}:</strong>
                  <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid ms-3'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value={item.status}
                      defaultChecked={item.status}
                      onClick={() => edit_status(!item.status, item.id)}
                    />
                    <label className='form-check-label ms-2'>
                      {item.status ? 'Enabled' : 'Disabled'}
                    </label>
                  </div>
                </p>
                <div className='mt-6'>
                  {check.edit && (
                    <Link
                      to={`/Discounts/Edit-Discounts/${item.id}`}
                      className='btn btn-primary text-white me-2 col-5'
                    >
                      {intl.formatMessage({id: 'Table.Edit'})}
                    </Link>
                  )}
                  {check.delete && (
                    <button
                      onClick={() => Delete(item.id)}
                      className='btn btn-danger text-white col-5'
                    >
                      {intl.formatMessage({id: 'Table.Delete'})}
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))
        ) : (
          <Skeleton
            variant='rounded'
            animation='wave'
            sx={{width: '100%', height: '90vh', marginTop: '1vh'}}
          />
        )}

        {/* Pagination for card view */}
        <div className='mt-6 d-flex justify-content-center'>
          <Pagination
            count={Math.ceil(filteredItems.length / itemsPerPage) || 1} // Ensure count is at least 1
            page={currentPage}
            onChange={handlePageChange}
            color='primary'
          />
        </div>
      </div>
    </motion.nav>
  )
}

export default GroupsHome
