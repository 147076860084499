import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link, useNavigate, useParams} from 'react-router-dom'
import axiosInstance from '../../../api/axios'
import Swal from 'sweetalert2'
import {motion} from 'framer-motion'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import 'flatpickr/dist/themes/light.css'
import Flatpickr from 'react-flatpickr'
import {FormControl, InputLabel, MenuItem, Select, Skeleton} from '@mui/material'
import moment from 'moment'
import ReactTable from '../../../../_metronic/partials/widgets/React_Table/ReactTable'
import useAuthContext from '../../../Auth/AuthContext'
import { Images } from '../../../../_metronic/partials/widgets/React_Table/Images'

const UsersGroups = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [Items, setItems] = useState([])
  const navigate = useNavigate()
  const [imported, setImported] = useState([])
  const {id} = useParams()

  const {check_role} = useAuthContext()

  const [data, setData] = useState({
    name_ar: '',
    name_en: '',
    percentage: 0,
    fixed: 0,
    type: 'fixed',
  })

  const [step, setStep] = useState(1)

  const types = [
    {id: 'fixed', name: 'percentage'},
    {id: 'variable', name: 'fixed '},
  ]

  const handleChange = (e) => {
    const {name, value} = e.target
    setData((prevData) => ({...prevData, [name]: value}))
  }

  const CustomDiv = () => (
    <div className='row flex-end w-100'>
        <div className='col-auto'>
          <button onClick={import_all} type='button' className='btn btn-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            {intl.formatMessage({ id: 'Table.Select_All' })}
          </button>
        </div>
        {imported.length > 0 &&
          <div className='col-auto'>
            <button onClick={remove_all} type='button' className='btn btn-danger'>
              <KTSVG path='/media/icons/duotune/abstract/abs012.svg' className='svg-icon-2' />
              {intl.formatMessage({ id: 'Table.Remove_All' })}
            </button>
          </div>
        }
    </div>
  )

  const handleEdit_Add_submit = async (event) => {
    setLoading(true)
    event.preventDefault()
    const formData = new FormData()
    formData.append('data', JSON.stringify(imported))
    formData.append('id', id)
    try {
      await axiosInstance.post(`/admin-groups-users`, formData).then((response) => {
        Swal.fire({
          title: 'Success',
          icon: 'success',
          confirmButtonText: 'OK',
        })
        setLoading(false)
        setErrors([])
        navigate(-1)
      })
    } catch (e) {
      console.log(e.response.data.errors)
      if (e.response.status === 422) {
        setLoading(false)
        setErrors(e.response.data.errors)
      }
    }
  }

  // get data
  const get_data = async () => {
    setItems([])
    try {
      const [groupResponse, usersGroupResponse] = await Promise.all([
        axiosInstance.get(`/admin-groups/${id}`),
        axiosInstance.get('/admin-users'),
      ])

      const groupData = groupResponse.data

      setData({
        name_ar: groupData.name_ar,
        name_en: groupData.name_en,
        percentage: groupData.percentage ?? 0,
        fixed: groupData.fixed ?? 0,
        type: groupData.type,
      })

      let selectedUsers = []
      if (groupData.users) {
        selectedUsers = groupData.users.map((user) => ({
          id: user.id,
          name: user.name,
          email: user.email,
        }))
        setImported(selectedUsers)
      }

      const allItems = usersGroupResponse.data

      const sortedItems = [
        ...selectedUsers,
        ...allItems.filter((item) => !selectedUsers.some((user) => user.id === item.id)),
      ]

      setItems(sortedItems)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const import_all = () => {
    setImported([])
    const allItems = Items.map(item => ({
      id: item.id,
      name: item.name,
      email: item.email,
    }));
    setImported(allItems);
  }

  const remove_all = () => {
    setImported([])
  }

 
  const add_item = (item) => {
    const rowsInput = {
      id: item.id,
      name: item.name,
      email: item.email,
    }
    setImported((prevImported) => [...prevImported, rowsInput])
  }

  const remove_item = (item) => {
    setImported((prevImported) =>
      prevImported.filter((importedItem) => importedItem.id !== item.id)
    )
  }

  const handle_change = (e, item) => {
    if (e.target.checked) {
      add_item(item)
    } else {
      remove_item(item)
    }
  }

  const isChecked = (id) => {
    return imported.some((importedItem) => importedItem.id === id)
  }

 

  const columns = [
    {
      Header: intl.formatMessage({id: 'Table.ID'}),
      accessor: 'productID',
      Cell: (row) => (
        <div className='w-100 flex-center'>
          <div className='form-check form-check-sm form-check-custom form-check-solid flex-center'>
            <input
              className='form-check-input widget-9-check me-2'
              onChange={(e) => {
                handle_change(e, row.row.original)
              }}
              type='checkbox'
              checked={isChecked(row.row.original.id)}
            />
            # {row.row.original.id}
          </div>
        </div>
      ),
    },

    {
      Header: intl.formatMessage({id: 'Table.National_id'}),
      accessor: 'national_id',
      Cell: (row) => (
        <div className=' w-100 flex-center'>{row.row.original.national_id ?? '---'}</div>
      ),
    },
    {
      Header: intl.formatMessage({id: 'Table.User'}),
      accessor: (row) => `${row.name} ${row.email}`,
      Cell: (row) => (
        <a target='_blank' href={`/Users/Edit-Users/${row.row.original.id}`}>
          <div className='d-flex align-items-center ps-3'>
            <Images img={row.row.original.img} name={row.row.original.name} />
            <div className='flex-grow-1'>
              <div className='text-dark fw-bold text-hover-primary text-start fs-6'>
                {row.row.original.name ? row.row.original.name : '---'}
              </div>
              <span className='text-muted d-block fw-semibold text-start'>
                {row.row.original.email}
              </span>
            </div>
          </div>
        </a>
      ),
    },
    {
      Header: intl.formatMessage({id: 'Table.Phone'}),
      accessor: 'phone',
      Cell: (row) => <div className=' w-100 flex-center'>{row.row.original.phone}</div>,
    },
  ]

  useEffect(() => {
    if (!check_role('show-groups')) {
      navigate('/')
    }
    get_data()
  }, [])

  // console.log(data)

  return (
    <motion.nav
      initial={{opacity: 0, x: 100}}
      animate={{opacity: 1, x: 0, transition: {duration: 0.3}}}
      exit={{opacity: 0, x: 100, transition: {duration: 0.2}}}
    >
      <div className=' mb-5 mt-5 d-flex flex-stack'>
        <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
          <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
            {intl.formatMessage({id: 'Groups.Edit'})}
          </h1>
          <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
            <li className='breadcrumb-item text-muted'>
              <span className='text-muted text-hover-primary'>
                {' '}
                {intl.formatMessage({id: 'Menu.Groups'})}
              </span>
            </li>
            <li className='breadcrumb-item'>
              <span className='bullet bg-gray-400 w-5px h-2px'></span>
            </li>
            <li className='breadcrumb-item text-muted'>
              {intl.formatMessage({id: 'Menu.Groups'})}
            </li>
          </ul>
        </div>
        <div>
          <Link to='/Groups' type='button' className='btn btn-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-2' />
            {intl.formatMessage({id: 'Form.GoBack'})}
          </Link>
        </div>
      </div>
      <div id='kt_app_content_container' className='app-container container-xxl'>
        <form
          onSubmit={handleEdit_Add_submit}
          id='kt_ecommerce_add_category_form'
          className='form d-flex flex-column flex-lg-row fv-plugins-bootstrap5 fv-plugins-framework'
          data-kt-redirect='../../demo1/dist/apps/ecommerce/catalog/categories.html'
        >
          <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
            {step === 1 && (
              <>
                <div className='card card-flush py-4'>
                  <div className='card-header'>
                    <div className='card-title'>
                      <h2>{intl.formatMessage({id: 'Form.General'})} </h2>
                    </div>
                  </div>

                  <div className='card-body pt-0'>
                    <div className='row flex-between'>
                      <div className=' my-5 col-6 fv-row fv-plugins-icon-container'>
                        <label className='required form-label'>
                          {' '}
                          {intl.formatMessage({id: 'Form.NameAR'})}{' '}
                        </label>
                        <input
                          type='text'
                          readOnly
                          name='name_ar'
                          className='form-control py-5'
                          value={data.name_ar}
                          onChange={handleChange}
                          placeholder={intl.formatMessage({id: 'Form.NameAR'})}
                        />
                        <div className='fv-plugins-message-container invalid-feedback' />
                        {errors.name_ar && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{errors.name_ar}</span>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className=' my-5 col-6 fv-row fv-plugins-icon-container'>
                        <label className='required form-label'>
                          {' '}
                          {intl.formatMessage({id: 'Form.NameEN'})}{' '}
                        </label>
                        <input
                          readOnly
                          type='text'
                          name='name_en'
                          className='form-control py-5'
                          value={data.name_en}
                          onChange={handleChange}
                          placeholder={intl.formatMessage({id: 'Form.NameEN'})}
                        />
                        <div className='fv-plugins-message-container invalid-feedback' />
                        {errors.name_en && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{errors.name_en}</span>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className='my-5 col-6 fv-row fv-plugins-icon-container'>
                        <label className='form-label'>
                          {intl.formatMessage({id: 'Form.Type'})}
                        </label>
                        <select
                          name='type'
                          readOnly
                          className='form-control py-5'
                          value={data.type}
                          onChange={handleChange}
                        >
                          {types.map((type) => (
                            <option key={type.id} value={type.id}>
                              {type.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      {data.type === 'variable' ? (
                        <div className=' my-5 fv-row col-6 fv-plugins-icon-container'>
                          <label className='required form-label'>
                            {' '}
                            {intl.formatMessage({id: 'Form.Fixed'})}
                          </label>
                          <input
                            type='number'
                            name='fixed'
                            readOnly
                            className='form-control py-5'
                            value={data.fixed}
                            onChange={handleChange}
                            placeholder={intl.formatMessage({id: 'Form.Fixed'})}
                          />
                          <div className='fv-plugins-message-container invalid-feedback' />
                          {errors.fixed && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errors.fixed}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className=' my-5 fv-row col-6 fv-plugins-icon-container'>
                          <label className='required form-label'>
                            {' '}
                            {intl.formatMessage({id: 'Form.Percentage'})}
                          </label>
                          <input
                            type='number'
                            name='percentage'
                            className='form-control py-5'
                            value={data.percentage}
                            onChange={handleChange}
                            placeholder={intl.formatMessage({id: 'Form.Percentage'})}
                          />
                          <div className='fv-plugins-message-container invalid-feedback' />
                          {errors.percentage && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errors.percentage}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
            <>
              <ReactTable columns={columns} data={Items} customComponent={<CustomDiv />} />
              <div className='row flex-between my-3 justify-content-end'>
                {check_role('edit-groups') && (
                  <button type='submit' className='btn col-2 btn-primary'>
                    {!loading && (
                      <span className='indicator-label'>
                        {intl.formatMessage({id: 'Form.Edit'})}{' '}
                      </span>
                    )}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        {intl.formatMessage({id: 'Form.Pleasewait'})}{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                )}
              </div>
            </>
          </div>
        </form>
      </div>
    </motion.nav>
  )
}

export default UsersGroups
