import React, {useState} from 'react'
import {Modal, Button} from 'react-bootstrap'
import {Form, Table} from 'react-bootstrap'
import Swal from 'sweetalert2'

import {useIntl} from 'react-intl'
import axiosInstance from '../../../api/axios'

const StockModal = ({show, handleClose, get_data}) => {
  const intl = useIntl()
  const [file, setFile] = useState(null)
  const [errors, setErrors] = useState('')
  const [loading, setLoading] = useState(false)

  const changeHandler_iamge = (event) => {
    setFile(event.target.files[0])
  }

  const handleEdit_Add_submit = async (event) => {
    event.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append('enctype', 'multipart/form-data')
    formData.append('file', file)

    if (!file) {
      setErrors({file: intl.formatMessage({id: 'Error.NoFileSelected'})})
      setLoading(false)
      return
    }

    try {
      await axiosInstance.post(`/admin-stocks-import`, formData).then((response) => {
        get_data()
        Swal.fire({
          title: 'Success',
          icon: 'success',
          confirmButtonText: 'OK',
        })
        setLoading(false)
        handleClose(false)
        // setFile(null)
      })
    } catch (e) {
      if (e.response.status === 422) {
        setLoading(false)
        setErrors(e.response.data.errors)
      }
    }
  }

  return (
    <Modal show={show} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Import Stocks</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex mt-5'>
          <div className='fv-row col-12' data-kt-password-meter='true'>
            <div className='mb-1'>
              <label className='form-label fw-bolder text-dark fs-6'>
                {intl.formatMessage({id: 'Table.ImportFormat'})}
              </label>
              <Table bordered>
                <thead>
                  <tr>
                    <th style={{border: ' black solid 0.5px', width: 'auto'}}>
                      {' '}
                      {intl.formatMessage({id: 'Table.Productid'})}{' '}
                    </th>
                    <th style={{border: ' black solid 0.5px', width: 'auto'}}>
                      {' '}
                      {intl.formatMessage({id: 'Table.RefNumber'})}{' '}
                    </th>

                    <th style={{border: ' black solid 0.5px', width: 'auto'}}>
                      {' '}
                      {intl.formatMessage({id: 'Table.Code'})}{' '}
                    </th>
                  </tr>
                </thead>
              </Table>
            </div>
          </div>
        </div>
        <form onSubmit={handleEdit_Add_submit}>
          <div className='d-flex mt-5'>
            <div className='fv-row col-12' data-kt-password-meter='true'>
              <div className='mb-1'>
                <label className='form-label fw-bolder text-dark fs-6'>File</label>
                <div className='position-relative mb-3'>
                  <Form.Control type='file' onChange={changeHandler_iamge} accept='.xls,.xlsx' />
                  <p className='text-muted mt-2'>
                    {intl.formatMessage({
                      id: 'Error.ExcelFileOnly',
                      defaultMessage: '* Please upload a file in Excel format (.xls or .xlsx).',
                    })}
                  </p>
                </div>
                {errors.file && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.file}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          {intl.formatMessage({id: 'Button.Close'})}{' '}
        </Button>
        <Button
          disabled={loading}
          onClick={handleEdit_Add_submit}
          variant='primary'
          style={{cursor: loading ? 'not-allowed' : 'pointer'}}
        >
          {loading ? 'Loading' : intl.formatMessage({id: 'Table.Import'})}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default StockModal
